<template>
  <div class="download">
    <Banner></Banner>
    <h2 class="h2">最新版本</h2>
    <div class="news">
      <div class="icon">
        <div class="edition">
          <img src="@/assets/img/pc/product/news_win.png" @click="windows(version)" />
          <h3>Windows</h3>
          <p>{{ version.releaseCode }}</p>
          <p>{{ version.releaseDate }}</p>
          <p class="explain">系统要求<img v-popover:popover1 src="@/assets/img/pc/product/explain.png" /></p>
          <el-popover ref="popover1" placement="bottom" trigger="hover" popper-class="popper">
            <div>系统：Windows2016 / Windows2019</div>
            <div>浏览器：IE10+，Chrome，360，QQ</div>
            <div>硬件：CPU(8核+)/内存(16G)/硬盘(1T+)</div>
          </el-popover>
        </div>
        <div class="edition" style="margin-left: 1.052631rem;">
          <img src="@/assets/img/pc/product/news_linux.png" @click="linux(version)" />
          <h3>Linux</h3>
          <p>{{ version.releaseCode }}</p>
          <p>{{ version.releaseDate }}</p>
          <p class="explain">系统要求<img v-popover:popover2 src="@/assets/img/pc/product/explain.png" /></p>
          <el-popover ref="popover2" placement="bottom" trigger="hover" popper-class="popper">
            <div>系统：CENTOS 7.X</div>
            <div>浏览器：IE10+，Chrome，360，QQ</div>
            <div>硬件：CPU(8核+)/内存(16G)/硬盘(1T+)</div>
          </el-popover>
        </div>
      </div>

      <div class="text">
        <h3>优化：</h3>
        <div class="mcaseshtml" v-html="html(version.releaseLog)"></div>
      </div>
    </div>
    <h2 class="h2">历史版本</h2>
    <div class="version">
      <div class="each" v-for="(item, index) in versionList" :key="index">
        <div class="left">
          <div class="times">
            <span>{{ item.releaseCode }}</span>
            <span>{{ item.releaseDate }}</span>
          </div>
          <p>优化:</p>
          <div class="mdhtml" v-html="html(item.releaseLog)"></div>
        </div>
        <div class="right">
          <div class="down">
            <span @click="windows(item)">Windows下载</span>
            <span @click="linux(item)">Linux下载</span>
          </div>
        </div>
        <div class="circular">
          <div></div>
        </div>
      </div>
      <div class="more" v-if="isfoot"><span @click="more">查看更多</span><span class="icon-chakangengduo1 iconfont"></span></div>
      <div v-else class="mores">
        <span>我也是有底线的</span>
      </div>
    </div>
    <FootMain></FootMain>
  </div>
</template>

<script>
import Banner from '@/components/m/product/product.vue'
import FootMain from '@/components/m/foot_main/index.vue'
export default {
  data() {
    return {
      data: {
        page: {
          cur: 1,
          size: 5,
          param: {}
        }
      }
    }
  },
  components: {
    Banner,
    FootMain
  },
  methods: {
    html(string) {
      return string
      if (string) return JSON.parse(string)
    },
    more() {
      this.data.page.cur += 1
      this.$store.dispatch('getVersionList', this.data)
    },
    windows(item) {
      let loginuser = localStorage.getItem('loginUser')
      if (loginuser == null || loginuser == 'undefined' || loginuser == '') {
        this.$router.push('/m/trial')
      } else {
        this.$store.dispatch('statistics', { id: item.id, type: 'win' })
      }
    },
    linux(item) {
      let loginuser = localStorage.getItem('loginUser')
      if (loginuser == null || loginuser == 'undefined' || loginuser == '') {
        this.$router.push('/m/trial')
      } else {
        this.$store.dispatch('statistics', { id: item.id, type: 'linux' })
        // window.open(item.linuxDownloadUrl, "_blank");
      }
    }
  },
  computed: {
    versionList() {
      return this.$store.state.versionList
    },
    version() {
      return this.$store.state.version
    },
    isfoot() {
      return this.$store.state.isfoot
    }
  },
  created() {
    this.$store.dispatch('getVersionList')
  }
}
</script>

<style lang="scss" scoped>
.download {
  overflow: hidden;
  h2 {
    margin: 2.127659rem 0;
  }
  .news {
    width: 31.914893rem;
    margin: 0 auto;
    margin-top: 1.842105rem;
    .icon {
      display: flex;
      justify-content: space-around;
    }
    .edition {
      text-align: center;
      width: 12.765957rem;
      background-image: url('~@/assets/img/pc/product/edition.png');
      background-size: 100% 100%;
      overflow: hidden;
      img {
        width: 8rem;
        height: 8rem;
        display: block;
        margin: 1.842105rem auto 0;
      }
      h3 {
        font-size: 1.578947rem;
        color: #333;
        margin: 0.425531rem 0;
      }

      p {
        // font-size: 0.789473rem;
        font-size: 0.9rem;
        color: #666;
        margin: 0;
      }

      .explain {
        color: #999;
        // font-size: 0.631578rem;
        font-size: 0.9rem;
        margin: 0.789473rem 0;

        img {
          display: inline;
          margin: 0 0.394736rem 0;
          vertical-align: middle;
          height: 0.789473rem;
          width: 0.789473rem;
          cursor: pointer;
        }
      }
    }
    .text {
      width: 100%;
      box-sizing: border-box;
      padding: 0 2.978723rem;
      h3 {
        font-size: 1.315789rem;
        color: #333;
      }
      div {
        // font-size: 0.947368rem;
        font-size: 1.1rem;
        line-height: 2rem;
        color: #888;
        margin-bottom: 0.789473rem;
      }
    }
  }

  .version {
    width: 90%;
    margin: 2rem auto 2rem;
    border-left: 1px solid #999;
    box-sizing: border-box;
    padding-top: 0.394736rem;
    .each {
      display: flex;
      justify-content: space-between;
      padding: 1.052631rem 2rem 0 3.289473rem;
      box-sizing: border-box;
      min-height: 9.605263rem;
      width: 100%;
      position: relative;
      &:first-of-type {
        .circular {
          top: 1.18421rem;
        }
      }
      &:hover {
        background-color: #f5fbf3;
        .circular {
          border-color: #22ac38;
          div {
            display: block;
          }
        }
      }
      .left {
        font-size: 1.052631rem;
        color: #333333;
        flex: 1;
        .times {
          display: flex;
          justify-content: space-between;
        }
      }
      p {
        font-size: 1rem;
        color: #666;
        margin: 0.236842rem 0;
      }
      .right {
        align-self: center;
        div {
          span {
            color: #000;
            font-size: 0.894736rem;
            display: block;
            cursor: pointer;
            text-decoration: underline;
            width: 6.382978rem;
            margin-right: 0;
            &:first-child {
              margin-bottom: 0.921052rem;
            }
          }
        }
      }
      .circular {
        width: 1.18421rem;
        height: 1.18421rem;
        border-radius: 50%;
        border: 0.131578rem solid #707070;
        box-sizing: border-box;
        position: absolute;
        top: 0.315789rem;
        left: 0;
        transform: translateX(-50%);
        background-color: #fff;
        div {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 0.342105rem;
          height: 0.342105rem;
          border-radius: 50%;
          background-color: #22ac38;
          display: none;
        }
      }
    }
  }

  .more {
    text-align: center;
    color: #2568d9;
    font-size: 0.894736rem;
    margin-top: 1.315789rem;

    span {
      font-size: 0.894736rem;
      cursor: pointer;
    }
  }
  .mores {
    text-align: center;
    color: #666;
    font-size: 0.894736rem;
    margin-top: 1.315789rem;
    span {
      font-size: 0.894736rem;
      cursor: pointer;
      position: relative;
      &::before {
        display: block;
        content: '';
        position: absolute;
        width: 80px;
        height: 1px;
        border-bottom: 1px solid #666;
        left: -85px;
        top: 50%;
        transform: translateY(-50%);
      }
      &::after {
        display: block;
        content: '';
        position: absolute;
        width: 80px;
        height: 1px;
        border-bottom: 1px solid #666;
        right: -85px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.h2 {
  text-align: center;
  font-size: 1.578947rem;
  color: #333;
  margin: 0;

  &:before {
    display: inline-block;
    content: '';
    height: 0.263157rem;
    width: 5.526315rem;
    background-color: #11e323;
    vertical-align: middle;
    transform: translateY(-50%);
    margin: 0 0.394736rem;
  }

  &:after {
    display: inline-block;
    content: '';
    height: 0.263157rem;
    width: 5.526315rem;
    background-color: #11e323;
    vertical-align: middle;
    transform: translateY(-50%);
    margin: 0 0.394736rem;
  }
}
</style>
<style>
.mdhtml p {
  font-size: 1rem;
  color: #666;
  margin: 0.236842rem 0;
}
.mcaseshtml {
  margin: 0 !important;
}
.mcaseshtml p {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.3rem;
  color: #888;
  margin-bottom: 0.789473rem;
}
</style>
